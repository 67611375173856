import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _dec3, _dec4, _obj;
/* import __COLOCATED_TEMPLATE__ from './second-factor-form.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { action } from "@ember/object";
import { SECOND_FACTOR_METHODS } from "discourse/models/user";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
import TEMPLATE from "./second-factor-form.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend((_dec = discourseComputed("secondFactorMethod"), _dec2 = discourseComputed("secondFactorMethod"), _dec3 = discourseComputed("secondFactorMethod", "isLogin"), _dec4 = discourseComputed("backupEnabled", "totpEnabled", "secondFactorMethod"), (_obj = {
  secondFactorTitle(secondFactorMethod) {
    switch (secondFactorMethod) {
      case SECOND_FACTOR_METHODS.TOTP:
        return I18n.t("login.second_factor_title");
      case SECOND_FACTOR_METHODS.SECURITY_KEY:
        return I18n.t("login.second_factor_title");
      case SECOND_FACTOR_METHODS.BACKUP_CODE:
        return I18n.t("login.second_factor_backup_title");
    }
  },
  secondFactorDescription(secondFactorMethod) {
    switch (secondFactorMethod) {
      case SECOND_FACTOR_METHODS.TOTP:
        return I18n.t("login.second_factor_description");
      case SECOND_FACTOR_METHODS.SECURITY_KEY:
        return I18n.t("login.security_key_description");
      case SECOND_FACTOR_METHODS.BACKUP_CODE:
        return I18n.t("login.second_factor_backup_description");
    }
  },
  linkText(secondFactorMethod, isLogin) {
    if (isLogin) {
      return secondFactorMethod === SECOND_FACTOR_METHODS.TOTP ? "login.second_factor_backup" : "login.second_factor";
    } else {
      return secondFactorMethod === SECOND_FACTOR_METHODS.TOTP ? "user.second_factor_backup.use" : "user.second_factor.use";
    }
  },
  showToggleMethodLink(backupEnabled, totpEnabled, secondFactorMethod) {
    return backupEnabled && totpEnabled && secondFactorMethod !== SECOND_FACTOR_METHODS.SECURITY_KEY;
  },
  toggleSecondFactorMethod(event) {
    event?.preventDefault();
    const secondFactorMethod = this.secondFactorMethod;
    this.set("secondFactorToken", "");
    if (secondFactorMethod === SECOND_FACTOR_METHODS.TOTP) {
      this.set("secondFactorMethod", SECOND_FACTOR_METHODS.BACKUP_CODE);
    } else {
      this.set("secondFactorMethod", SECOND_FACTOR_METHODS.TOTP);
    }
  }
}, (_applyDecoratedDescriptor(_obj, "secondFactorTitle", [_dec], Object.getOwnPropertyDescriptor(_obj, "secondFactorTitle"), _obj), _applyDecoratedDescriptor(_obj, "secondFactorDescription", [_dec2], Object.getOwnPropertyDescriptor(_obj, "secondFactorDescription"), _obj), _applyDecoratedDescriptor(_obj, "linkText", [_dec3], Object.getOwnPropertyDescriptor(_obj, "linkText"), _obj), _applyDecoratedDescriptor(_obj, "showToggleMethodLink", [_dec4], Object.getOwnPropertyDescriptor(_obj, "showToggleMethodLink"), _obj), _applyDecoratedDescriptor(_obj, "toggleSecondFactorMethod", [action], Object.getOwnPropertyDescriptor(_obj, "toggleSecondFactorMethod"), _obj)), _obj))));